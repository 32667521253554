import React from "react"
import LogoLoader from "../assets/images/logo.png"
import "../styles/Loader.css"

function Loader() {
  return (
    <div className="loader-wrapper">
      <div className="loader-container">
        <div className="loader-ring outer"></div>
        <div className="loader-ring inner"></div>
        <div className="loader-circle">
          <img src={LogoLoader || "/placeholder.svg"} alt="Loading" className="loader-logo" />
        </div>
        <div className="loader-dots">
          {[...Array(8)].map((_, i) => (
            <div key={i} className="loader-dot" style={{ "--dot-index": i }} />
          ))}
        </div>
        <div className="loader-progress">
          <span className="loader-progress-dot"></span>
        </div>
      </div>
    </div>
  )
}

export default Loader

