import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Search } from "lucide-react"
import { MdOutlineTune, MdCancel } from "react-icons/md"
import { FaLessThan, FaGreaterThan } from "react-icons/fa6"
import PriceRangeSlider from "./PriceRangeSlider"
import Marquee from "./Marquee"
import axios from "axios"
import Loader from "../Components/Loader"
import { GetAllBillBoardsDetailsUsUrl } from "../Constants"
import "../styles/ExploreBillboards.css"

const BILLBOARD_TYPES = ["3D", "Static", "Digital", "Mobile", "Posters"]
const CITIES = ["Karachi", "Islamabad", "Hyderabad", "Lahore", "Faisalabad"]
const STATES = ["Sindh", "Punjab", "KPK", "Balochistan"]

function ExploreBillboards() {
  const navigate = useNavigate()
  const [billboards, setBillboards] = useState([])
  const [filteredBillboards, setFilteredBillboards] = useState([])
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [searchInput, setSearchInput] = useState("")

  const [filters, setFilters] = useState({
    typeOfBillBoard: [],
    brandType: [],
    state: [],
    city: [],
    region: [],
    minPrice: "",
    maxPrice: "",
    search: "",
  })

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 9,
  })

  const [filterOptions, setFilterOptions] = useState({
    typeOfBillBoard: BILLBOARD_TYPES,
    brandType: [],
    state: STATES,
    city: CITIES,
    region: [],
  })

  const fetchBillboardDetails = async (filters, pagination) => {
    try {
      const params = new URLSearchParams()
      params.append("page", pagination.currentPage.toString())
      params.append("limit", pagination.limit.toString())

      // Add non-empty filters to params
      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          value.forEach((v) => params.append(key, v))
        } else if (value && value !== "") {
          params.append(key, value.toString())
        }
      })

      const response = await axios.get(`${GetAllBillBoardsDetailsUsUrl}?${params.toString()}`)
      return response.data
    } catch (error) {
      console.error("Error fetching billboard details:", error)
      throw error
    }
  }

  const fetchBillboards = async () => {
    setLoading(true)
    setError(null)

    try {
      const data = await fetchBillboardDetails(filters, pagination)
      const billboardsData = data.billboards || []
      setBillboards(billboardsData)
      filterBillboards(billboardsData, searchInput)
      updateFilterOptions(billboardsData)

      setPagination((prev) => ({
        ...prev,
        totalPages: data.pagination?.totalPages || 1,
      }))
    } catch (error) {
      console.error("Failed to fetch billboards:", error)
      setError("Failed to load billboards. Please try again later.")
    } finally {
      setLoading(false)
    }
  }

  const filterBillboards = (billboardsData, searchTerm) => {
    if (!searchTerm) {
      setFilteredBillboards(billboardsData)
      return
    }

    const searchLower = searchTerm.toLowerCase()
    const filtered = billboardsData.filter((billboard) => billboard.name.toLowerCase().includes(searchLower))
    setFilteredBillboards(filtered)
  }

  const updateFilterOptions = (billboards) => {
    const newFilterOptions = {
      typeOfBillBoard: BILLBOARD_TYPES,
      brandType: [],
      state: STATES,
      city: CITIES,
      region: [],
    }

    setFilterOptions(newFilterOptions)
  }

  const toggleFilter = () => setIsFilterOpen(!isFilterOpen)

  const handleFilterChange = (filterType, value) => {
    setFilters((prev) => {
      const newFilters = { ...prev }
      if (Array.isArray(newFilters[filterType])) {
        if (newFilters[filterType].includes(value)) {
          newFilters[filterType] = newFilters[filterType].filter((item) => item !== value)
        } else {
          newFilters[filterType] = [...newFilters[filterType], value]
        }
      } else {
        newFilters[filterType] = value
      }
      return newFilters
    })
  }

  const handleRemoveFilter = (filterType, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: Array.isArray(prev[filterType]) ? prev[filterType].filter((item) => item !== value) : "",
    }))
  }

  const handlePriceRangeChange = ({ min, max }) => {
    setFilters((prev) => ({
      ...prev,
      minPrice: min.toString(),
      maxPrice: max.toString(),
    }))
  }

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      setPagination((prev) => ({ ...prev, currentPage: newPage }))
    }
  }

  const handleSearch = (value) => {
    setSearchInput(value)
    filterBillboards(billboards, value)
  }

  useEffect(() => {
    fetchBillboards()
  }, [filters, pagination.currentPage])

  // Update filtered billboards when search input changes
  useEffect(() => {
    filterBillboards(billboards, searchInput)
  }, [searchInput, billboards])

  const renderActiveFilters = () => {
    const activeFilters = []

    // Add price range filter if both min and max are set
    if (filters.minPrice && filters.maxPrice) {
      activeFilters.push(
        <div key="price-range" className="filter-tag">
          <span>
            Rs{filters.minPrice} - Rs{filters.maxPrice}
          </span>
          <button className="remove-filter" onClick={() => handlePriceRangeChange({ min: "", max: "" })}>
            <MdCancel className="h-4 w-4" />
          </button>
        </div>,
      )
    }

    // Add other filters
    Object.entries(filters).forEach(([filterType, values]) => {
      if (filterType !== "minPrice" && filterType !== "maxPrice" && filterType !== "search" && Array.isArray(values)) {
        values.forEach((value) => {
          activeFilters.push(
            <div key={`${filterType}-${value}`} className="filter-tag">
              <span>{value}</span>
              <button className="remove-filter" onClick={() => handleRemoveFilter(filterType, value)}>
                <MdCancel className="h-4 w-4" />
              </button>
            </div>,
          )
        })
      }
    })

    return activeFilters
  }

  return (
    <div className="bg-black min-h-screen text-white">
      <div className="explore-container">
        <div className="explore-layout">
          {/* Mobile Filter Toggle */}
          <button className="mobile-filter-toggle" onClick={toggleFilter}>
            <MdOutlineTune />
            <span>Filters</span>
          </button>

          {/* Filters Sidebar */}
          <div className={`sidebar ${isFilterOpen ? "open" : ""}`}>
            <div className="filter-header">
              <div className="filter-align">
                <MdOutlineTune />
                <span>Filters</span>
              </div>
              <button className="filter-cancel-button" onClick={toggleFilter}>
                <MdCancel />
              </button>
            </div>
            <div className="sidebar-scroll">
              <div className="filter-sections">
                <div className="filter-section for-border-b">
                  {!loading && (
                    <div className="price-range">
                      <h3>Select Your Price Range</h3>
                      <PriceRangeSlider
                        onChange={handlePriceRangeChange}
                        initialMin={Number.parseInt(filters.minPrice) || 0}
                        initialMax={Number.parseInt(filters.maxPrice) || 30000}
                        min={0}
                        max={30000}
                      />
                    </div>
                  )}
                  <h3>Types Of Billboards</h3>
                  <div className="checkbox-group">
                    {BILLBOARD_TYPES.map((type) => (
                      <label key={type} className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={filters.typeOfBillBoard.includes(type)}
                          onChange={() => handleFilterChange("typeOfBillBoard", type)}
                        />
                        <span>{type}</span>
                      </label>
                    ))}
                  </div>
                </div>

                {/* State Filter */}
                <div className="filter-section for-border-b">
                  <h3>State</h3>
                  <div className="checkbox-group">
                    {STATES.map((state) => (
                      <label key={state} className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={filters.state.includes(state)}
                          onChange={() => handleFilterChange("state", state)}
                        />
                        <span>{state}</span>
                      </label>
                    ))}
                  </div>
                </div>

                {/* City Filter */}
                <div className="filter-section for-border-b">
                  <h3>City</h3>
                  <div className="checkbox-group">
                    {CITIES.map((city) => (
                      <label key={city} className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={filters.city.includes(city)}
                          onChange={() => handleFilterChange("city", city)}
                        />
                        <span>{city}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="main-content">
            {/* Search Bar */}
            <div className="search-container">
              <div className="search-input-wrapper">
                <Search className="search-icon" />
                <input
                  type="text"
                  className="search-input"
                  placeholder="Enter Road or Area Name"
                  value={searchInput}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>

            {/* Active Filters */}
            {!loading && <div className="active-filters">{renderActiveFilters()}</div>}

            {/* Error Message */}
            {error && <div className="error-message">{error}</div>}

            {/* Billboard Grid and Loader */}
            <div className="billboard-grid">
              {loading ? (
                <Loader />
              ) : filteredBillboards.length > 0 ? (
                filteredBillboards.map((billboard) => (
                  <div key={billboard.id} className="billboard-card">
                    <img
                      src={billboard.primaryImage || "/placeholder.svg"}
                      alt={billboard.name}
                      className="billboard-image"
                      onClick={() =>
                        navigate("/billboard-detail", {
                          state: { product: billboard },
                        })
                      }
                    />
                    <div className="billboard-info">
                      <h3>{billboard.name}</h3>
                      <p>{billboard.description}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No billboards found.</p>
              )}
            </div>

            {/* Pagination */}
            {!loading && filteredBillboards.length > 0 && (
              <div className="pagination">
                <button
                  onClick={() => handlePageChange(pagination.currentPage - 1)}
                  disabled={pagination.currentPage === 1}
                >
                  <FaLessThan />
                </button>
                {Array.from({ length: pagination.totalPages }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => handlePageChange(i + 1)}
                    className={pagination.currentPage === i + 1 ? "active" : ""}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(pagination.currentPage + 1)}
                  disabled={pagination.currentPage === pagination.totalPages}
                >
                  <FaGreaterThan />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Marquee />
    </div>
  )
}

export default ExploreBillboards

