import axios from "axios";

export const BaseUrl = "https://urchin-app-jzd5t.ondigitalocean.app/";
// export const BaseUrl = "http://192.168.18.46:3000/";

export const ContactUsUrl = BaseUrl + "contactus/";

export const GetAllBillBoardsUsUrl =
  BaseUrl + "billboardCheckRole/getFilteredBillboards";

export const GetAllBillBoardsDetailsUsUrl =
  BaseUrl + "billboardCheckRole/getFilteredBillboards";
export const GetAllPortfolioUsUrl = BaseUrl + "portfolioCheckRole/getAll";


export const GetAllServicesUrl = BaseUrl + "serviceCheckRole/getAll";
     