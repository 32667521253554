import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/HoverCards.css";
import { GetAllServicesUrl } from "../Constants";

const HoverCards = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAllServices = async () => {
    try {
      const response = await fetch(GetAllServicesUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch services: ${response.statusText}`);
      }

      const data = await response.json();
      setCards(data.services.filter((service) => service.status)); // Only include active services
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllServices();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional for smooth scrolling
    });
  };

  const handleCardClick = (card) => {
    navigate(`/service-detail`, { state: card });
  };

  if (loading) {
    return <div>Loading services...</div>;
  }

  if (error) {
    return <div>Error loading services: {error}</div>;
  }

  return (
    <div className="hover-cards-container" onClick={scrollToTop}>
      {cards.map((card) => (
        <div
          className="card"
          key={card.id}
          onClick={() => handleCardClick(card)}
        >
          <div className="card-content">
            <h3>{card.title}</h3>
            <p>{card.description?.details || "No description available"}</p>
            <button>Explore</button>
          </div>
          <div className="card-image">
            <img
              src={card.primaryImage}
              alt={card.title}
              onError={(e) => {
                e.target.src = "/path-to-placeholder-image.png"; // Fallback image
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default HoverCards;
