import React, { useState } from "react";
import "../styles/Home.css"; // Import the CSS file
import { ContactUsUrl } from "../Constants";
import Toast from "../Components/Toast";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ visible: false, message: "", type: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(ContactUsUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      const data = await response.json();
      setToast({
        visible: true,
        message: "Message sent successfully!",
        type: "success",
      });
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
    } catch (err) {
      setToast({
        visible: true,
        message: "Failed to send message. Please try again.",
        type: "error",
      });
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      {" "}
      <div className="contact-us-outer">
        <div className="contact-us-home">
          <div className="text-side">
            <h1>
              WANT TO <br /> WORK WITH <br /> US?
            </h1>
          </div>
          <div className="for-center-border"></div>
          <div className="form-side">
            <div className="contact-home-input2">
              <h3>CONTACT US</h3>
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
                required
              />

              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                // required
              ></textarea>
            </div>
            <div className="input-innertex">
              <p>Vorem ipsum dolor sit amet, consectetur adipiscing elitm, .</p>{" "}
              <br />
              <p>Selmorebillboards!23@gmail.com</p> <br />
              <p>(+92) 123 678904</p>
              <div className="contactus-main-btn2">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toast.visible && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ visible: false, message: "", type: "" })}
        />
      )}
    </div>
  );
}

export default ContactForm;
