import '../styles/PriceRangeSlider.css';
import React, { useState, useEffect, useCallback } from "react"

const PriceRangeSlider = ({ onChange, initialMin = 0, initialMax = 30000, min = 0, max = 30000, debounceMs = 500 }) => {
  const [priceRange, setPriceRange] = useState([initialMin, initialMax])
  const [minInput, setMinInput] = useState(initialMin.toString())
  const [maxInput, setMaxInput] = useState(initialMax.toString())

  useEffect(() => {
    setMinInput(priceRange[0].toString())
    setMaxInput(priceRange[1].toString())
  }, [priceRange])

  const debouncedOnChange = useCallback(
    (values) => {
      const handler = setTimeout(() => {
        onChange(values)
      }, debounceMs)

      return () => {
        clearTimeout(handler)
      }
    },
    [onChange, debounceMs],
  )

  const handleSliderChange = (event, index) => {
    const newValue = Number.parseInt(event.target.value)
    const newPriceRange = [...priceRange]
    newPriceRange[index] = newValue

    if (index === 0 && newValue <= priceRange[1]) {
      setPriceRange(newPriceRange)
      debouncedOnChange({ min: newValue, max: priceRange[1] })
    } else if (index === 1 && newValue >= priceRange[0]) {
      setPriceRange(newPriceRange)
      debouncedOnChange({ min: priceRange[0], max: newValue })
    }
  }

  const handleInputChange = (value, isMin) => {
    const numValue = Number.parseInt(value)
    if (isNaN(numValue)) return

    if (isMin) {
      setMinInput(value)
      if (numValue <= priceRange[1] && numValue >= min) {
        setPriceRange([numValue, priceRange[1]])
        debouncedOnChange({ min: numValue, max: priceRange[1] })
      }
    } else {
      setMaxInput(value)
      if (numValue >= priceRange[0] && numValue <= max) {
        setPriceRange([priceRange[0], numValue])
        debouncedOnChange({ min: priceRange[0], max: numValue })
      }
    }
  }

  return (
    <div className="relative">
      <div className="price-range-slider">
        <div className="slider-container">
          <input
            type="range"
            min={min}
            max={max}
            value={priceRange[0]}
            onChange={(e) => handleSliderChange(e, 0)}
            className="slider slider-0"
          />
          <input
            type="range"
            min={min}
            max={max}
            value={priceRange[1]}
            onChange={(e) => handleSliderChange(e, 1)}
            className="slider slider-1"
          />
        </div>
        <div className="range-inputs">
          <div className="input-group">
            <label htmlFor="min-price">Min</label>
            <input
              id="min-price"
              type="number"
              value={minInput}
              onChange={(e) => handleInputChange(e.target.value, true)}
              placeholder="Min"
            />
          </div>
          <span className="separator">-</span>
          <div className="input-group">
            <label htmlFor="max-price">Max</label>
            <input
              id="max-price"
              type="number"
              value={maxInput}
              onChange={(e) => handleInputChange(e.target.value, false)}
              placeholder="Max"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PriceRangeSlider

