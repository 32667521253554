import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { FaCircleCheck } from "react-icons/fa6";
import { TbFileDescription } from "react-icons/tb";
import ContactUsPages from "./ContactForm";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../styles/Detail.css";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Detail = () => {
  const location = useLocation();
  const { product } = location.state || { product: null };

  if (!product) {
    return <div>No product selected</div>;
  }

  // Combine ImageUrl array and primaryImage if it's not already included
  const allImages = product.ImageUrl || [];
  if (product.primaryImage && !allImages.includes(product.primaryImage)) {
    allImages.unshift(product.primaryImage);
  }

  // If no images are available, use a placeholder
  const images = allImages.length > 0 ? allImages : ["/placeholder.svg"];
  const size = `${product.facewidth}x${product.faceheight}`;

  return (
    <div>
      <div className="detail-content">
        <div className="detail-billboard-intro">
          <p>{product.name}</p>
          <div className="product-price">
            <h1>Rs {product.pricePerDay}/day</h1>
          </div>
        </div>

        <div className="product-top-btn">
          {/* Add your buttons here if needed */}
        </div>

        <div className="detail-image">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={images.length > 1}
            className="mySwiper"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image || "/placeholder.svg"}
                  alt={`${product.name} - View ${index + 1}`}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    e.target.src = "/placeholder.svg";
                    e.target.onerror = null;
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="product-loaction">
          <TbFileDescription />
          <h3>{product.description}</h3>
        </div>
      </div>
      <div className="details-text">
        <h3>Details</h3>
      </div>

      <div className="bottom-detail-main">
        <div className="bottom-detail-main-left">
          <div className="bottom-left-inner1">
            <span>Price per Day:</span>
            <p>{product.pricePerDay}/day</p>
          </div>
          <div className="bottom-left-inner1">
            <span>Price Per Week:</span>
            <p>Rs {product.pricePerWeek}/week</p>
          </div>
          <div className="bottom-left-inner1">
            <span>Price Per Month:</span>
            <p>Rs {product.pricePerMonth}/month</p>
          </div>
          <div className="bottom-left-inner1">
            <span>Price Per Year:</span>
            <p>{product.pricePerYear}/year</p>
          </div>
        </div>
        <div className="bottom-detail-main-right">
          <div className="bottom-detail-main-right1">
            <span>BillBoard Type</span>
            <p>{product.typeOfBillBoard}</p>
          </div>
          <div className="bottom-left-inner1">
            <span>Size:</span>
            <p>{size}</p>
          </div>
          <div className="bottom-left-inner1">
            <span>Structure:</span>
            <p>{product.structure}</p>
          </div>
          <div className="bottom-left-inner1">
            <span>Facing:</span>
            <p>{product.facing}</p>
          </div>
          {/* <div className="bottom-detail-main-right1">
            <span>Contact:</span>
            <p>+9212345676</p>
          </div> */}
        </div>
      </div>
      <div className="details-text">
        <h3>Address</h3>
      </div>
      <div className="section-2bottom">
        <div className="bottom-detail-main2">
          <div className="bottom-detail-main-left">
            <div className="bottom-left-inner1">
              <span>Address:</span>
              <p>{product.address}</p>
            </div>
            <div className="bottom-left-inner1">
              <span>Location:</span>
              <p>{product.location}</p>
            </div>
            <div className="bottom-left-inner1">
              <span>Facing:</span>
              <p>{product.facing}</p>
            </div>
          </div>
          <div
            className="bottom-detail-main-right"
            style={{ paddingRight: "10px" }}
          >
            <div className="bottom-detail-main-right1">
              <span>State:</span>
              <p>{product.state}</p>
            </div>
            <div className="bottom-detail-main-right1">
              <span>City:</span>
              <p>{product.city}</p>
            </div>
            <div className="bottom-detail-main-right1">
              <span>Region:</span>
              <p>{product.region}</p>
            </div>
          </div>
        </div>
        <div className="Features-details">
          <h3>Features</h3>
          <div className="features-main">
            <div className="features">
              <FaCircleCheck />
              <p>Bottom Lights</p>
            </div>
            <div className="features">
              <FaCircleCheck />
              <p>Generator Backup</p>
            </div>
            <div className="features">
              <FaCircleCheck />
              <p>Top Lights</p>
            </div>
          </div>
        </div>
      </div>

      <div className="book-now-btn">
        <a
          href="https://ops.selmoreadvertising.com"
          onClick={scrollToTop}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="book-nowbtn">Book Now</button>
        </a>
      </div>

      <div className="">
        <ContactUsPages />
      </div>
    </div>
  );
};

export default Detail;
