import React, { useState } from "react";
import "../styles/ContactUs.css";
import bgimg from "../images/Glow-1.png";
import phone from "../images/Phone.png";
import GoogleMap from "../pages/GoogleMap";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneFlip } from "react-icons/fa6";
import { ContactUsUrl } from "../Constants";
import Toast from "../Components/Toast";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ visible: false, message: "", type: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(ContactUsUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      const data = await response.json();
      setToast({
        visible: true,
        message: "Message sent successfully!",
        type: "success",
      });
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
    } catch (err) {
      setToast({
        visible: true,
        message: "Failed to send message. Please try again.",
        type: "error",
      });
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="home-container">
        <div className="home-we-provide">
          <h3>CONTACT US</h3>
        </div>
      </div>
      <div className="contact-us-main">
        <div className="contact-us-main-left">
          <h3>CONNECT WITH US</h3>
          <p>
            Qorem ipsum dolor sit amet, consect adipiscing elit. li dolor sit{" "}
            <br />
            amet, consect adipiscing el putate li
          </p>
          <div className="features-Help contact-us-gap">
            <div className="feature-Help section-gapc2">
              <MdEmail />
              <div className="section-space">
                <p>Selmorebillboards!23@gmail.coms</p>
              </div>
            </div>
            <div className="section-gapc3">
              <FaPhoneFlip />
              <div className="feature-Help">
                <p>(+92) 123 678904</p>
              </div>
            </div>
            <div className="ADDRESS">
              <h3>ADDRESS</h3>
              <div className="feature-Help section-gapc">
                <FaLocationDot />
                <div className="section-space">
                  <p>
                    Vorem ipsum dolor sit amet, consectetur adipiscing elitm, .
                  </p>
                </div>
              </div>
              <div className="feature-Help section-gapc">
                <FaLocationDot />
                <div className="section-space">
                  <p>
                    Vorem ipsum dolor sit amet, consectetur adipiscing elitm, .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-main-right">
          <h3>SEND US A MESSAGE</h3>
          <form onSubmit={handleSubmit} className="contactus-main-from">
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleChange(e);
                }
              }}
              required
            />

            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              // required
            ></textarea>
            <div className="contactus-main-btn">
              <button type="submit" disabled={loading}>
                {loading ? "Sending..." : "Send"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {toast.visible && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ visible: false, message: "", type: "" })}
        />
      )}
      <div className="map-show">
        <GoogleMap />
      </div>
    </div>
  );
}

export default ContactUs;
