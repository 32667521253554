import React, { useState, useEffect, useCallback } from "react";
import "../styles/PortfolioSection.css";
import { FaEye } from "react-icons/fa";
import axios from "axios";
import { GetAllPortfolioUsUrl } from "../Constants";

const PortfolioCarousel = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState("right");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const itemsPerPage = 6;
  const totalPages = Math.ceil((portfolioData?.length || 0) / itemsPerPage);

  // Fetch portfolio data from API
  const fetchPortfolioData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await axios.get(GetAllPortfolioUsUrl);
      if (response.data?.portfolios) {
        setPortfolioData(response.data.portfolios);
      } else {
        setPortfolioData([]);
      }
    } catch (error) {
      console.error("Error fetching portfolio data:", error);
      setError("Failed to load portfolio data");
      setPortfolioData([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Get the current items to display based on the page
  const getCurrentItems = useCallback(() => {
    if (!portfolioData?.length) return [];
    const start = currentPage * itemsPerPage;
    return portfolioData.slice(start, start + itemsPerPage);
  }, [currentPage, portfolioData]);

  const nextPage = useCallback(() => {
    if (totalPages <= 1) return;
    setSlideDirection("right");
    setCurrentPage((prev) => (prev + 1) % totalPages);
  }, [totalPages]);

  const prevPage = useCallback(() => {
    if (totalPages <= 1) return;
    setSlideDirection("left");
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  }, [totalPages]);

  const nextImage = useCallback(
    (e) => {
      if (e) e.stopPropagation();
      if (selectedItem?.ImageUrl?.length) {
        setCurrentImageIndex((prev) => (prev + 1) % selectedItem.ImageUrl.length);
      }
    },
    [selectedItem]
  );

  const handleItemClick = (item) => {
    if (!item) return;
    setSelectedItem(item);
    setCurrentImageIndex(0);
  };

  const goToPage = (pageIndex) => {
    if (pageIndex === currentPage || pageIndex >= totalPages) return;
    setSlideDirection(pageIndex > currentPage ? "right" : "left");
    setCurrentPage(pageIndex);
  };

  const goToImage = (imageIndex) => {
    if (!selectedItem?.ImageUrl?.length || imageIndex >= selectedItem.ImageUrl.length) return;
    setCurrentImageIndex(imageIndex);
  };

  // Autoplay for main carousel
  useEffect(() => {
    fetchPortfolioData();
  }, []);

  useEffect(() => {
    if (totalPages <= 1) return;
    
    const interval = setInterval(() => {
      nextPage();
    }, 5000);

    return () => clearInterval(interval);
  }, [nextPage, totalPages]);

  // Autoplay for popup slider
  useEffect(() => {
    if (!selectedItem?.ImageUrl?.length) return;
    
    const interval = setInterval(nextImage, 5000);
    return () => clearInterval(interval);
  }, [selectedItem, nextImage]);

  if (isLoading) {
    return <div className="portfolio-loading">Loading...</div>;
  }

  if (error) {
    return <div className="portfolio-error">{error}</div>;
  }

  return (
    <section className="portfolio-section">
      <div className="portfolio-header">
        <p className="portfolio-subtitle">Our Work</p>
        <h2 className="portfolio-title">Advertising Portfolio</h2>
        <p className="portfolio-description">
          We are a full-service advertising media agency dedicated to helping brands
          reach and engage their target audiences. Using strategic insights and
          creative solutions across digital, social, print, and outdoor platforms,
          we bring your brand's vision to life and maximize its impact.
        </p>
      </div>

      <div className="portfolio-container">
        <div className={`portfolio-grid slide-${slideDirection}`}>
          {getCurrentItems().map((item) => (
            <div
              key={item?.id || Math.random()}
              className="portfolio-item"
              onClick={() => handleItemClick(item)}
            >
              <img
                src={item?.primaryImage || "/placeholder.svg"}
                alt={item?.title || "Portfolio item"}
              />
              <div className="overlay">
                <span className="plus-sign">
                  <FaEye/>
                </span>
              </div>
              <div className="item-details">
                <h3>{item?.title || "Untitled"}</h3>
                <p>{item?.adType || "Advertisement"}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {totalPages > 1 && (
        <div className="carousel-dots">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`carousel-dot ${currentPage === index ? "active" : ""}`}
              onClick={() => goToPage(index)}
              aria-label={`Go to page ${index + 1}`}
            />
          ))}
        </div>
      )}

      {selectedItem && (
        <div className="popup-overlay" onClick={() => setSelectedItem(null)}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button
              className="close-button"
              onClick={() => setSelectedItem(null)}
              aria-label="Close popup"
            >
              ×
            </button>
            <div className="popup-image-slider">
              <div className="popup-image">
                <img
                  src={
                    selectedItem?.ImageUrl?.[currentImageIndex] || "/placeholder.svg"
                  }
                  alt={`${selectedItem?.title || "Image"} - ${currentImageIndex + 1}`}
                />
                {selectedItem?.ImageUrl?.length > 0 && (
                  <div className="image-counter">
                    {currentImageIndex + 1} / {selectedItem.ImageUrl.length}
                  </div>
                )}
              </div>
              {selectedItem?.ImageUrl?.length > 1 && (
                <div className="popup-slider-dots">
                  {selectedItem.ImageUrl.map((_, index) => (
                    <button
                      key={index}
                      className={`popup-slider-dot ${
                        currentImageIndex === index ? "active" : ""
                      }`}
                      onClick={() => goToImage(index)}
                      aria-label={`Go to image ${index + 1}`}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="popup-details">
              <h2>{selectedItem?.title || "Untitled"}</h2>
              <span className="category">{selectedItem?.adType || "Advertisement"}</span>
              <p className="description">{selectedItem?.client || "Client information unavailable"}</p>
              <div className="project-info">
                <div className="info-item">
                  <strong>Client:</strong> {selectedItem?.client || "N/A"}
                </div>
                <div className="info-item">
                  <strong>Date:</strong> {selectedItem?.date || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default PortfolioCarousel;
