import React from "react";
import { MdEmail, MdPhone, MdLocationOn } from "react-icons/md";
import { FaFacebook, FaInstagram, FaLinkedin, FaArrowUp } from "react-icons/fa";
import "../styles/Footer.css";
import mainlogo from "../images/new logo selmore 2 1.png"

import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <div className="footer__wave">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="wave-fill"
          ></path>
        </svg>
      </div>

      <div className="footer__container">
        {/* Logo Section */}
        <div className="footer__section footer__brand">
          <img
            src={mainlogo}
            alt="Selmore Logo"
            className="footer__logo"
          />
          <p className="footer__description">
            Your premier destination for outdoor advertising solutions. Making
            your brand visible where it matters most.
          </p>
        </div>

        {/* Navigation Links */}
        <div className="footer__section">
          <h4 className="footer__heading">Navigate with Ease</h4>
          <ul className="footer__links">
            <li>
              <Link to="/" onClick={scrollToTop}>
                <span className="link__line"></span>
                Home
              </Link>
            </li>
            <li>
              <Link to="/services" onClick={scrollToTop}>
                <span className="link__line"></span>
                Services
              </Link>
            </li>
            <li>
              <Link to="/about-us" onClick={scrollToTop}>
                <span className="link__line"></span>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/explore" onClick={scrollToTop}>
                <span className="link__line"></span>
                Explore Billboards
              </Link>
            </li>
            <li>
              <Link to="/contact-us" onClick={scrollToTop}>
                <span className="link__line"></span>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/faqs" onClick={scrollToTop}>
                <span className="link__line"></span>
                FAQs
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="footer__section">
          <h4 className="footer__heading">Contact Us</h4>
          <ul className="footer__contact">
            <li>
              <a
                href="mailto:info@selmore.com"
                className="footer__contact-item"
              >
                <MdEmail className="contact__icon" />
                <span>info@selmore.com</span>
              </a>
            </li>
            <li>
              <a href="tel:+922345678901" className="footer__contact-item">
                <MdPhone className="contact__icon" />
                <span>+92 (234) 567-890</span>
              </a>
            </li>
            <li className="footer__contact-item">
              <MdLocationOn className="contact__icon" />
              <span>Sunset Tower, Phase-2, DHA, Karachi</span>
            </li>
          </ul>
          <div className="footer__section">
          <h4 className="footer__heading">Get in Touch</h4>
          <div className="footer__social">
            <a href="#" className="social__link">
              <FaFacebook />
            </a>
            <a href="#" className="social__link">
              <FaInstagram />
            </a>
            <a href="#" className="social__link">
              <FaLinkedin />
            </a>
          </div>
        </div>
        </div>

        {/* Social Links */}
        
      </div>

      {/* Copyright */}
      <div className="footer__bottom">
        <p>© {new Date().getFullYear()} Selmore. All rights reserved.</p>
      </div>

      {/* Scroll to Top Button */}
      <button onClick={scrollToTop} className="scroll-to-top">
        <FaArrowUp />
      </button>
    </footer>
  );
};

export default Footer;
